import React, { useState, useEffect } from 'react';
import logo from './images/logo.png';

const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false); // State to manage menu visibility
    const [scrolled, setScrolled] = useState(false); // State to manage scroll position

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header
            className={`fixed w-full top-0 left-0 z-50 transition-colors duration-300 ${
                scrolled ? 'bg-neutral-50 text-black' : 'bg-cream-50 text-black'
            }`}
        >
            <div className="flex items-center justify-between p-2">
                {/* Logo Section */}
                <div className="flex items-center space-x-2">
                    <img
                        src={logo}
                        alt="AutoDrive Logo"
                        className="h-12 w-12 object-contain"
                    />
                    <h1 className="text-lg font-bold">Unik Builders</h1>
                </div>
                <nav className="hidden md:flex space-x-4">
                    <a href="#home" className="no-underline text-black-900 font-bold md:hover:bg-yellow-700 md:hover:text-white px-2 py-1 md:transition md:rounded duration-200">Home</a>
                    <a href="#contact" className="no-underline text-black-900 font-bold md:hover:bg-yellow-700 md:hover:text-white px-2 py-1 md:transition md:rounded duration-200">Contact</a>
                </nav>
                <div className="md:hidden">
                    <button onClick={() => setIsNavOpen(!isNavOpen)} className="focus:outline-none">
                        {/* Hamburger Icon */}
                        <span className="block h-0.5 w-8 bg-black mb-1"></span>
                        <span className="block h-0.5 w-8 bg-black mb-1"></span>
                        <span className="block h-0.5 w-8 bg-black"></span>
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isNavOpen && (
    <div className={`absolute top-full left-0 w-full ${scrolled ? 'bg-neutral-50' : 'bg-cream-50'} text-black md:hidden`}>
            <a href="#home" className="block px-4 py-2 no-underline text-black-900 font-bold hover:bg-yellow-700 hover:text-white transition duration-200 rounded">Home</a>
            <a href="#contact" className="block px-4 py-2 no-underline text-black-900 font-bold hover:bg-yellow-700 hover:text-white transition duration-200 rounded">Contact</a>
          </div>
      )}
        </header>
    );
};

export default Header;