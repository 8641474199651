import React, { useEffect, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import newHousesData from './data/newHouses.json';
import underConstructionData from './data/underConstruction.json';
import renovatedData from './data/renovated.json';
import oldPropertiesData from './data/oldProperties.json';
import ContactForm from './ContactForm.js';
import Header from './Header.js';

const images = [
  'https://vaarivana.com/wp-content/uploads/2023/05/Unveiling_the_Splendor.jpg.webp',
  'https://www.satyamevgroups.com/blog/wp-content/uploads/2019/12/residential-apartment-in-patna-840x480.jpg',
  'https://img.freepik.com/premium-photo/3d-rendering-individual-modern-house_21255-27.jpg?w=826',
  'https://img.onmanorama.com/content/dam/mm/en/lifestyle/decor/images/2024/9/9/renovated-house-c.jpg',
  'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi4yPg3twFrhVKnzyOdaAJrF9WqCkp7IzxWbcqm-apXL4sscpbB3d1gK1yVSUEw1LcfZ9llIS5sXy5GOKFLTS7euBBKJca0XqMn85ps9zLrJb5_-Zhzh1Ju4ED9xfT9QEtibI5ZqpjebRvy/s1600/before-after-pictures-kerala-house-renovation.jpg'
];

const App = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [newHouses, setNewHouses] = useState([]);
  const [underConstruction, setUnderConstruction] = useState([]);
  const [renovated, setRenovated] = useState([]);
  const [oldProperties, setOldProperties] = useState([]);

  useEffect(() => {
    setNewHouses(newHousesData);
    setUnderConstruction(underConstructionData);
    setRenovated(renovatedData);
    setOldProperties(oldPropertiesData);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-cream-50">
      {/* Header */}
     <Header />



      <div className="mt-24 p-4">


        {/* Carousel */}
        <div className="relative w-11/12 max-w-6xl h-96 mb-4 overflow-hidden mx-auto">
          <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="w-full h-full object-cover transition-opacity duration-500" />
        </div>

        {/* Dots for Navigation */}
        <div className="flex justify-center space-x-2 mb-4">
          {images.map((_, index) => (
            <button key={index} className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-yellow-700' : 'bg-gray-300'}`} onClick={() => setCurrentIndex(index)} />
          ))}
        </div>

        {/* Sections for Each Property Category */}
        <div className="p-6">

          <h2 className="text-3xl font-extrabold mt-10 mb-8 text-transparent bg-clip-text bg-gradient-to-r from-yellow-700 to-emerald-500 drop-shadow-md transition-transform hover:scale-105">
            New Houses
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {newHouses.map((property) => (
              <div
                key={property.id}
                className="relative overflow-hidden border border-gray-200 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                <img
                  src={property.image}
                  alt={property.name}
                  className="w-full h-48 object-cover rounded-t-lg transition-opacity duration-300 hover:opacity-90"
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold mt-2 mb-1 text-gray-800">{property.name}</h3>
                  <p className="text-gray-600 mb-1">Location: {property.location}</p>
                  <p className="text-gray-600 mb-2">Price: {property.price}</p>
                </div>
              </div>
            ))}
          </div> <h2 className="text-3xl font-extrabold mt-10 mb-8 text-transparent bg-clip-text bg-gradient-to-r from-yellow-700 to-emerald-500 drop-shadow-md transition-transform hover:scale-105">
            Under Construction
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {underConstruction.map((property) => (
              <div
                key={property.id}
                className="relative overflow-hidden border border-gray-200 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                <img
                  src={property.image}
                  alt={property.name}
                  className="w-full h-48 object-cover rounded-t-lg transition-opacity duration-300 hover:opacity-90"
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold mt-2 mb-1 text-gray-800">{property.name}</h3>
                  <p className="text-gray-600 mb-1">Location: {property.location}</p>
                  <p className="text-gray-600 mb-2">Price: {property.price}</p>
                </div>
              </div>
            ))}
          </div> <h2 className="text-3xl font-extrabold mt-10 mb-8 text-transparent bg-clip-text bg-gradient-to-r from-yellow-700 to-emerald-500 drop-shadow-md transition-transform hover:scale-105">
            Renovated
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {renovated.map((property) => (
              <div
                key={property.id}
                className="relative overflow-hidden border border-gray-200 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                <img
                  src={property.image}
                  alt={property.name}
                  className="w-full h-48 object-cover rounded-t-lg transition-opacity duration-300 hover:opacity-90"
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold mt-2 mb-1 text-gray-800">{property.name}</h3>
                  <p className="text-gray-600 mb-1">Location: {property.location}</p>
                  <p className="text-gray-600 mb-2">Price: {property.price}</p>
                </div>
              </div>
            ))}
          </div>
          <h2 className="text-3xl font-extrabold mt-10 mb-8 text-transparent bg-clip-text bg-gradient-to-r from-yellow-700 to-emerald-500 drop-shadow-md transition-transform hover:scale-105">
            Old Houses
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {oldProperties.map((property) => (
              <div
                key={property.id}
                className="relative overflow-hidden border border-gray-200 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl"
              >
                <img
                  src={property.image}
                  alt={property.name}
                  className="w-full h-48 object-cover rounded-t-lg transition-opacity duration-300 hover:opacity-90"
                />
                <div className="p-4">
                  <h3 className="text-xl font-bold mt-2 mb-1 text-gray-800">{property.name}</h3>
                  <p className="text-gray-600 mb-1">Location: {property.location}</p>
                  <p className="text-gray-600 mb-2">Price: {property.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Address and Contact Form */}
        <ContactForm /> 
        {/* Footer */}
        <footer className="bg-cream-50 p-4 text-black flex flex-col items-center">
          <div className="flex space-x-4 mb-2">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="text-yellow-700" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="text-yellow-700" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-yellow-700" />
            </a>
          </div>
          <div className="text-center">
            <p>
              &copy; 2024 <a href="https://hellolocal.in" target="_blank" rel="noopener noreferrer" className="text-purple-900 hover:underline">HelloLocal</a>. All rights reserved.
            </p>
          </div>
        </footer>
      </div>
    </div>

  );
};

export default App;
