import React, { useState } from 'react';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [statusMessage, setStatusMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage('Sending...');

    try {
      const response = await fetch('/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === 'success') {
          setStatusMessage('Email sent successfully!');
          setFormData({ name: '', email: '', phone: '', message: '' });
          setTimeout(() => setStatusMessage(''), 5000);
        } else {
          setStatusMessage('Error sending email.');
        }
      } else {
        setStatusMessage('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div
      id="contact"
      className="flex flex-col md:flex-row w-full max-w-6xl mx-auto mt-8 bg-white shadow-md rounded-lg p-4 gap-4"
    >
      {/* Address Section */}
      <div className="flex-1 flex flex-col space-y-4 p-4 bg-white">
        <h2 className="font-bold text-xl mb-4">Contact Us</h2>
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="text-yellow-700 mr-2" />
          <span>#26-2-4, Kambalacheruvu, Rajahmundry</span>
        </div>
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faPhoneAlt} className="text-yellow-700 mr-2" />
          <span>9032695455</span>
        </div>
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faEnvelope} className="text-yellow-700 mr-2" />
          <span>unik@hellolocal.in</span>
        </div>
      </div>

      {/* Form Section */}
      <form
        onSubmit={handleSubmit}
        className="flex-1 flex flex-col space-y-4 p-4 bg-gray-50 rounded-md shadow"
      >
        {/* Name and Mobile Fields */}
        <div className="flex flex-col md:flex-row md:space-x-2 gap-y-4">
          <input
            type="text"
            name="name"
            placeholder="Name"
            className="flex-1 p-2 border border-gray-300 rounded focus:border-yellow-500 focus:outline-none"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            placeholder="Mobile"
            className="flex-1 p-2 border border-gray-300 rounded focus:border-yellow-500 focus:outline-none"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        {/* Email Field */}
        <input
          type="email"
          name="email"
          placeholder="Email"
          className="w-full p-2 border border-gray-300 rounded focus:border-yellow-500 focus:outline-none"
          value={formData.email}
          onChange={handleChange}
          required
        />

        {/* Message Field */}
        <textarea
          name="message"
          placeholder="Message"
          className="w-full p-2 border border-gray-300 rounded focus:border-yellow-500 focus:outline-none"
          rows="4"
          value={formData.message}
          onChange={handleChange}
          required
        />

        {/* Submit Button */}
        <button
          type="submit"
          className="px-6 py-2 text-center bg-yellow-700 text-white rounded mx-auto hover:bg-emerald-700 transition-colors text-sm"
        >
          Submit
        </button>
      </form>

      {/* Status Message */}
      {statusMessage && (
        <div
          className={`w-full mt-4 p-2 text-center font-semibold rounded ${statusMessage.includes('Error')
              ? 'bg-red-200 text-red-700'
              : 'bg-green-200 text-green-700'
            }`}
        >
          {statusMessage}
        </div>
      )}
    </div>
  );
};

export default ContactForm;
